import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../utils';
import { Loader2 } from 'lucide-react';

const buttonVariants = cva(
  'inline-flex items-center justify-center text-sm font-medium ring-offset-background transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-40 [&:not(&:focus-visible)]:aria-invalid:ring-destructive aria-invalid:ring-2',
  {
    variants: {
      variant: {
        default: 'bg-primary text-white hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        'secondary-destructive':
          'bg-destructive/10 text-destructive hover:bg-destructive/15',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-gray-100 hover:bg-gray-200',
        ghost: 'hover:bg-gray-100',
        link: 'text-primary underline-offset-4 hover:underline',
        unstyled: '',
        workflow: 'text-[#3E80FF] bg-gray-200 font-medium focus-visible:ring-0',
      },
      size: {
        xs: 'h-6 min-w-[1.5rem] rounded px-2 text-xs',
        default: 'h-10 min-w-[2.5rem] text-base px-4 py-2 rounded-lg',
        sm: 'h-8 rounded-md px-3',
        lg: 'h-11 rounded-md px-8 text-lg',
        'icon-xs': 'h-6 w-6 rounded-md',
        'icon-sm': 'h-8 w-8 rounded-md',
        icon: 'h-10 w-10 rounded-lg',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
    compoundVariants: [
      {
        size: 'sm',
        variant: 'link',
        class: 'px-0',
      },
      {
        size: 'default',
        variant: 'workflow',
        class: 'h-7 px-3 py-0.5 rounded-md',
      },
    ],
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className,
      variant,
      size,
      disabled,
      loading = false,
      asChild = false,
      children,
      ...props
    },
    ref,
  ) => {
    const isDisabled = disabled || loading;

    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isDisabled}
        type={type}
        {...props}
      >
        {loading && <Loader2 className="me-2 h-4 w-4 animate-spin" />}
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
