import { z } from 'zod';

export enum UserState {
  NOT_VERIFIED = 'NOT_VERIFIED',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export const UserTraitsSchema = z.object({
  name: z.object({
    first: z.coerce.string(),
    last: z.coerce.string(),
  }),
});
export type UserTraits = z.infer<typeof UserTraitsSchema>;

export const UserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  traits: UserTraitsSchema,
  isAdmin: z.boolean(),
  state: z.enum(['NOT_VERIFIED', 'INACTIVE', 'ACTIVE']),
  state_changed_at: z.string().datetime(),
  updated_at: z.string().datetime(),
});
export type User = z.infer<typeof UserSchema>;

export function getFullName(traits: UserTraits): string | undefined {
  return traits.name.first || traits.name.last
    ? [traits.name.first, traits.name.last].join(' ')
    : undefined;
}

export function getUserTitle(user: Pick<User, 'traits' | 'email'>): string {
  const { first, last } = user.traits.name;
  if (first || last) {
    return [first, last].join(' ').trim();
  }
  return user.email.trim();
}
