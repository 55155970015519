export enum FieldViolationType {
  REQUIRED = 'REQUIRED',
  ERR_EMAIL_EXIST = 'ERR_EMAIL_EXIST',
  NOT_VALID = 'NOT_VALID',
  UNIQUE = 'UNIQUE',
}

export const ValidationErrorRuTranslation = {
  REQUIRED: 'Поле обязательно к заполнению',
  ERR_EMAIL_EXIST: 'Пользователь с таким email уже существует',
  UNIQUE: 'Не уникальное значение',
  NOT_VALID: 'Не правильно',
  VALIDATION_ERROR: 'Ошибка валидации',
  AUTH: 'Неверный email или пароль',
  ERR_UNKNOWN_USER: 'Пользователь с таким email не найден',
  ERR_USER_ALREADY_ACTIVATED: 'Пользователь уже активирован',
  ERR_EMAIL_ALREADY_SENT: 'Письмо с ссылкой уже отправлено',
};

export type BadRequestError = {
  code: 400;
  message: 'bad request';
  fieldViolations: FieldViolation[];
};
export function isBadRequestError(error: unknown): error is BadRequestError {
  const err = error as BadRequestError;
  return (
    !!err &&
    typeof err === 'object' &&
    typeof err.code === 'number' &&
    err.code === 400 &&
    Array.isArray(err.fieldViolations)
  );
}

export type FieldViolation = {
  field: string;
  type: FieldViolationType;
  description: string;
};

export type ErrorInfo = {
  reason: string;
  description: string;
  metadata: Record<string, string>;
};
export function isErrorInfo(error: unknown): error is ErrorInfo {
  const err = error as ErrorInfo;
  return (
    !!err &&
    typeof err === 'object' &&
    typeof err.reason === 'string' &&
    typeof err.description === 'string' &&
    typeof err.metadata === 'object'
  );
}

export function isNotFoundError(error: unknown): boolean {
  return isErrorInfo(error) && error.reason === 'NOT_FOUND';
}

export type ResponseError = {
  code: number;
  message: string;
};
export function isResponseError(error: unknown): error is ResponseError {
  const err = error as ResponseError;
  return (
    !!err &&
    typeof err === 'object' &&
    typeof err.code === 'number' &&
    typeof err.message === 'string'
  );
}

export type Error = BadRequestError | ResponseError | ErrorInfo;
