import '@/i18n';
import '@fontsource-variable/inter';
import '@fontsource-variable/roboto-mono';

import { init } from 'api/auth';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'ui/components/toaster';
import { TooltipProvider } from 'ui/components/tooltip';

import { App } from '@/App';

init(import.meta.env.VITE_AUTH_API_URL);

import '@/styles/index.css';

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <TooltipProvider>
    <App />
    <Toaster />
  </TooltipProvider>,
);
