import { queryClient } from '@/api/client';
import { setCurrentProjectId } from '@/api/projects';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  redirect,
  useLocation,
  useNavigate,
  useRevalidator,
} from 'react-router';

export function useProjectSelect(redirectTo: string = '/devices') {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { revalidate } = useRevalidator();

  const selectProject = useCallback(
    (projectId: string) => {
      // set
      setCurrentProjectId(projectId);
      if (redirectTo) {
        navigate(redirectTo, {
          replace: true,
        });
      } else {
        const rootPath = location.pathname.split('/')[1] ?? '';
        navigate('/' + rootPath, {
          replace: true,
        });
      }

      queryClient.clear();
      revalidate();

      // navigate(0);
    },
    [navigate],
  );

  return selectProject;
}

export function selectProject(
  projectId: string,
  redirectTo: string = '/devices',
): never {
  setCurrentProjectId(projectId);
  queryClient.clear();

  throw redirect(redirectTo);
}
