export default {
  paths: {},
  validation: {
    invalidIdentifier: 'Невалидный идентификатор',
    invalidIdentifier_withSymbols:
      'Идентификатор может содержать только латинские буквы, цифры и символы "-_"',
    lessThanMax: 'Минимальное значение должно быть меньше максимального',
    commandParamNameUnique: 'Название параметра должно быть уникальным',
  },
  workflows: {
    emptyParam: 'Необходимо заполнить параметр',
  },
};
