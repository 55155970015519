function flattenKeys(obj: any, name: string = ''): string[] {
  return Object.entries(obj).flatMap(([key, value]) => {
    if (typeof value === 'object') {
      return flattenKeys(value, name ? `${name}.${key}` : key);
    }
    return name ? `${name}.${key}` : key;
  });
}

export function translationDiff(tA: object, tB: object) {
  const res = new Set(flattenKeys(tA));
  const bKeys = flattenKeys(tB);

  for (const key of bKeys) {
    res.has(key) ? res.delete(key) : res.add(`[en] ${key}`);
  }
  return [...res];
}
