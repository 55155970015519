import { z } from 'zod';
import { User, UserTraitsSchema } from './user';

export type LoginRequest = {
  email: string;
  password: string;
  returnTo?: string;
};

export type LoginResponse = {
  session: Session;
  accessToken: string;
  refreshToken: string;
  returnTo: string;
};

export type RegistrationRequest = {
  email: string;
  password: string;
  returnTo?: string;
};

export type RegistrationResponse = {
  user: User;
  session: Session;
  accessToken: string;
  refreshToken: string;
  returnTo: string;
};

export type RefreshSessionResponseBody = {
  session: Session;
  accessToken: string;
  refreshToken: string;
};

export type Session = {
  active: boolean;
  authenticatedAt: string;
  expiresAt: string;
  id: string;
  user: User;
  issuedAt: string;
};

export type WhoAmIResponse = {
  active: boolean;
  authenticatedAt: string;
  expiresAt: string;
  id: string;
  user: User;
  issuedAt: string;
};

export const UpdateUserTraitsSchema = z.object({
  traits: UserTraitsSchema,
});
export type UpdateUserTraitsRequest = z.infer<typeof UpdateUserTraitsSchema>;

export const ChangeUserPasswordSchema = z.object({
  oldPassword: z.string().min(6),
  newPassword: z.string().min(6),
});
export type ChangeUserPasswordRequest = z.infer<
  typeof ChangeUserPasswordSchema
>;
