import { getErrorInfo } from '@/lib/error';
import { XOctagon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'ui/components/button';

export interface ErrorScreenProps {
  // eventId: string;
  // resetError(): void;
}
export function ErrorScreen({}: ErrorScreenProps) {
  const { t } = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();

  const { title, description, code, retry } = getErrorInfo(error);

  return (
    <div className="container mx-auto flex max-w-xl flex-col pt-20">
      <XOctagon className="text-destructive mx-auto mb-2 h-10 w-10" />
      <h1 className="text-center text-2xl font-bold">
        {/* {t('screens.error.title')} */}
        {title}
      </h1>
      <div className="my-4">
        {code && (
          <p>
            <span className="font-medium">{t('screens.error.errorCode')}:</span>{' '}
            {code}
          </p>
        )}

        <p>{description}</p>
      </div>
      {retry ? (
        <Button onClick={() => navigate(0)}>
          {t('screens.error.reloadPage')}
        </Button>
      ) : (
        <Button asChild>
          <Link to="/" reloadDocument>
            {t('screens.error.toMain')}
          </Link>
        </Button>
      )}
    </div>
  );
}
