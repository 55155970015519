import { Slot } from '@radix-ui/react-slot';
import React, { forwardRef } from 'react';
import { EmptyIcon } from '../illustrations';
import { cn } from '../utils';
import { XCircleIcon } from 'lucide-react';

export interface ResultProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'empty' | 'error';
}
export const Result: React.FC<ResultProps> = ({
  className,
  type = 'empty',
  children,
  ...props
}) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center text-center',
        className,
      )}
      {...props}
    >
      {type === 'empty' && <EmptyIcon className="mb-4" />}
      {type === 'error' && (
        <XCircleIcon className="text-destructive mb-4 size-10" />
      )}
      {children}
    </div>
  );
};

export interface ResultTitleProps
  extends React.HTMLAttributes<HTMLHeadingElement> {
  asChild?: boolean;
}
export const ResultTitle = forwardRef<HTMLHeadingElement, ResultTitleProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'h3';
    return <Comp ref={ref} className={cn('text-3xl', className)} {...props} />;
  },
);

export interface ResultDescriptionProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  asChild?: boolean;
}
export const ResultDescription = forwardRef<
  HTMLHeadingElement,
  ResultTitleProps
>(({ className, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'p';
  return (
    <Comp
      ref={ref}
      className={cn('mt-2 text-gray-500', className)}
      {...props}
    />
  );
});

export interface ResultContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}
export const ResultContent = forwardRef<HTMLDivElement, ResultContentProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return <Comp ref={ref} className={cn('p-4', className)} {...props} />;
  },
);
