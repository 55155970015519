import { toast } from 'sonner';
import { getErrorInfo } from './error';
import { t } from '@/i18n';

export function errorToast(error: any) {
  const info = getErrorInfo(error);

  toast.error(info.title, {
    description: info.code ? (
      <>
        <span className="font-medium">{t('screens.error.errorCode')}:</span>{' '}
        {info.code}
      </>
    ) : undefined,
  });
}
