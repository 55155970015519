import { z } from 'zod';
import { LabelField, NameField } from '@/api/common/schema';
import { RoleGroups } from './constants';
import { Permission } from 'api/permission';

const roleLiterals = RoleGroups.flatMap(g => g.roles.map(r => z.literal(r)));

export const RoleSchema = z.union([
  roleLiterals[0],
  roleLiterals[1],
  ...roleLiterals.slice(2),
]);

export const ProjectSchema = z.object({
  id: NameField,
  name: LabelField,
  description: z.string().optional(),

  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type Project = z.infer<typeof ProjectSchema>;

export type ProjectWithRoles = Project & { roles: string[] };

export const CreateProjectRequestSchema = ProjectSchema.pick({
  name: true,
  description: true,
}).extend({ projectId: NameField });

export type CreateProjectRequest = z.infer<typeof CreateProjectRequestSchema>;

export const UpdateProjectRequestSchema = CreateProjectRequestSchema;
export type UpdateProjectRequest = CreateProjectRequest;

export const ProjectMemberSchema = z.object({
  projectId: z.string(),
  userId: z.string(),
  userEmail: z.string().email(),
  roles: z.array(RoleSchema).min(1),
  firstName: z.string(),
  lastName: z.string(),

  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type ProjectMember = z.infer<typeof ProjectMemberSchema>;

export type AcceptInvitationRequest = {
  projectId: string;
  userEmail: string;
};

export const UpdateProjectMemberRequestSchema = ProjectMemberSchema.pick({
  projectId: true,
  userId: true,
  roles: true,
});
export type UpdateProjectMemberRequest = z.infer<
  typeof UpdateProjectMemberRequestSchema
>;

export type ListProjectPermissionsResponse = {
  permissions: Permission[];
};

export type Invitation = {
  projectId: string;
  userEmail: string;
  roles: string[];
  invitedById: string;
  createdAt: string;
  updatedAt: string;
};

export const CodeInvitationSchema = z.object({
  id: z.string(),
  projectId: z.string(),
  code: z.string(),
  roles: z.array(RoleSchema).min(1),

  expiresAt: z.string().datetime().optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type CodeInvitation = z.infer<typeof CodeInvitationSchema>;

export type LeaveProjectRequest = {
  projectId: string;
  userId: string;
};
