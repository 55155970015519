import { Children as ReactChildren, isValidElement } from 'react';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getValidChildren(children: React.ReactNode) {
  return ReactChildren.toArray(children).filter((child) =>
    isValidElement(child),
  ) as React.ReactElement[];
}
