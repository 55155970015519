import {
  DefaultError,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { queryClient } from '../client';

type OmitQuery<T> = Omit<T, 'queryKey' | 'queryFn'>;

export interface QueryFactoryResult<
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey,
> {
  queryKey: TQueryKey;
  queryFn: QueryFunction<TQueryFnData, TQueryKey>;
}

export function createQuery<
  TQueryParams = unknown,
  TQueryFnData = unknown,
  TError = DefaultError,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryFactory: (
    params: TQueryParams,
  ) => QueryFactoryResult<TQueryFnData, TQueryKey>,
) {
  function use<TData = TQueryFnData>(
    params: TQueryParams,
    options?: OmitQuery<
      DefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey>
    >,
  ): DefinedUseQueryResult<TData, TError>;
  function use<TData = TQueryFnData>(
    params: TQueryParams,
    options?: OmitQuery<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
    >,
  ): UseQueryResult<TData, TError>;

  function use<TData = TQueryFnData>(
    params: TQueryParams,
    options?: OmitQuery<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
    >,
  ) {
    return useQuery({
      ...queryFactory(params),
      ...options,
    });
  }
  function key(params: TQueryParams) {
    return queryFactory(params).queryKey;
  }

  function ensure(params: TQueryParams) {
    return queryClient.ensureQueryData({
      ...queryFactory(params),
    });
  }

  return {
    use,
    ensure,
    key,
  };
}
