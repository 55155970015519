import {
  useCallback,
  type Dispatch,
  type SetStateAction,
  useState,
  useEffect,
} from 'react';
import { useEventCallback } from './use-event-callback';
import { useEventListener } from './use-event-listener';

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage(
  key: string,
  initialValue: string,
): [string, SetValue<string>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback(() => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ?? initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<string> = useEventCallback(value => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, newValue);

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setValue];
}

export function setLocalStorageValue(key: string, value: string) {
  // Prevent build error "window is undefined" but keeps working
  if (typeof window === 'undefined') {
    console.warn(
      `Tried setting localStorage key “${key}” even though environment is not a client`,
    );
  }

  try {
    // Save to local storage
    window.localStorage.setItem(key, value);

    // We dispatch a custom event so every useLocalStorage hook are notified
    window.dispatchEvent(new Event('local-storage'));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
}

// A wrapper for "JSON.parse()"" to support "undefined" value
// function parseJSON<T>(value: string | null): T | undefined {
//   try {
//     return value === 'undefined' ? undefined : JSON.parse(value ?? '');
//   } catch {
//     console.log('parsing error on', { value });
//     return undefined;
//   }
// }
