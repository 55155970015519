import { RouteObject } from 'react-router';

export default {
  path: '/shared/:projectId',
  children: [
    {
      path: 'dashboards/:dashboardId',
      lazy: () => import('./[projectId]/dashboards/[dashboardId]/page'),
    },
  ],
} satisfies RouteObject;
