export default {
  axios: {
    ERR_NETWORK: 'Network error',
    ECONNABORTED: 'Server response timed out',
    ETIMEDOUT: 'Server response timed out',
  },
  info: {
    NOT_FOUND: 'Not found',
  },
};
