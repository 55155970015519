import WorkflowErrorPage from './[workflowId]/error';

export default {
  path: 'workflows',
  children: [
    {
      index: true,
      lazy: () => import('./page'),
    },
    {
      path: 'create',
      lazy: () => import('./create/page'),
    },
    {
      path: ':workflowId',
      errorElement: <WorkflowErrorPage />,
      lazy: () => import('./[workflowId]/page'),
    },
  ],
};
