import { z } from 'zod';

export const NameField = z
  .string()
  .max(60)
  .trim()
  .refine(arg => /^[a-zA-Z_-][a-zA-Z0-9_-]*$/.test(arg), {
    params: { i18n: 'validation.invalidIdentifier_withSymbols' },
  })
  .default('');

export const LabelField = z.string().max(60);
