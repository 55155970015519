export default {
  axios: {
    ERR_NETWORK: 'Проблемы с сетью',
    ECONNABORTED: 'Превышено время ожидания ответа от сервера',
    ETIMEDOUT: 'Превышено время ожидания ответа от сервера',
  },
  info: {
    NOT_FOUND: 'Страница не найдена',
  },
};
