export const APP_VERSION = __APP_VERSION__;

export const apiUrl =
  import.meta.env.VITE_API_BASE_URL || window.env.API_BASE_URL;

export const AUTH_API_URL = `${apiUrl}/auth`;
export const AUTH_APP_URL =
  import.meta.env.VITE_AUTH_APP_URL || window.env.AUTH_APP_URL;
export const PROJECTS_API_URL = `${apiUrl}`;
export const API_URL = apiUrl;
export const APP_BASENAME =
  import.meta.env.VITE_APP_BASENAME || window.env.APP_BASENAME;

const languagesEnv = import.meta.env.VITE_APP_LANGUAGES;

export const APP_DEFAULT_LANGUAGE =
  import.meta.env.VITE_APP_DEFAULT_LANGUAGE || 'ru';
export const APP_LANGUAGES = !!languagesEnv
  ? languagesEnv.split(',').map(lang => lang.trim().toLowerCase())
  : [];
