import { t } from '@/i18n';
import { isErrorInfo, type BadRequestError, type InfoError } from 'api/error';
import { isAxiosError, type AxiosError } from 'axios';

export function isNotFoundError(error: any): error is AxiosError<InfoError> {
  return isAxiosError<InfoError>(error) && error.response?.status === 404;
}

export function isBadRequestError(
  error: any,
): error is AxiosError<BadRequestError> {
  return (
    isAxiosError<BadRequestError>(error) &&
    Array.isArray(error.response?.data?.fieldViolations)
  );
}

export function asBadRequestError(error: any): BadRequestError | undefined {
  if (!isBadRequestError(error)) {
    return;
  }

  return error.response?.data;
}

export function asInfoError(error: any): InfoError | undefined {
  if (!isAxiosError(error) || !isErrorInfo(error.response?.data)) {
    return;
  }

  return error.response!.data as InfoError;
}

export function isUnauthenticatedError(error: unknown): boolean {
  return isAxiosError(error) && error?.response?.status === 401;
}

export interface ErrorInfo {
  title: string;
  description?: string;
  code?: string;

  retry?: boolean;
  empty: boolean;
}

export function getErrorInfo(error: any): ErrorInfo {
  if (!error) {
    return undefined as never;
    // return { title: '', empty: true };
  }

  if (!isAxiosError(error)) {
    return {
      title: 'Что-то пошло не так',
      code: '',
      description: error.message,
      empty: false,
    };
  }

  if (isNotFoundError(error)) {
    return {
      title: t('error:info.NOT_FOUND'),
      empty: false,
    };
  }
  const badRequestError = asBadRequestError(error);
  if (badRequestError) {
    return {
      title: 'bad_request',
      description: '',
      code: 'bad_request',
      empty: false,
    };
  }
  if (isUnauthenticatedError(error)) {
    return {
      title: 'unauthenticated',
      description: '',
      code: 'unauthenticated',
      empty: false,
    };
  }

  if (isErrorInfo(error.response?.data)) {
    const err = error.response?.data as InfoError;
    return {
      title: t(`error:info.${err.reason}`, {
        ns: 'error',
        defaultValue: err.description,
      }),
      code: error.response?.data.reason!,
      empty: false,
    };
  }

  return {
    title: t(`axios.${error.code as 'ECONNABORTED'}`, { ns: ['error'] }),
    description: error.message,
    code: error.code!,
    retry: true,
    empty: false,
  };
}
