export default {
  paths: {},
  validation: {
    invalidIdentifier: 'Invalid identifier',
    invalidIdentifier_withSymbols:
      'The identifier can contain only latin letters, numbers and symbols "-_"',
    lessThanMax: 'The minimum value must be less than the maximum',
    commandParamNameUnique: 'Param name must be unique',
  },
  workflows: {
    emptyParam: 'Empty parameter',
  },
};
