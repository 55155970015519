import type { BadRequestError, InfoError, ResponseError } from './types';

export function isBadRequestError(error: unknown): error is BadRequestError {
  const err = error as BadRequestError;
  return (
    !!err &&
    typeof err === 'object' &&
    typeof err.code === 'number' &&
    err.code === 400 &&
    Array.isArray(err.fieldViolations)
  );
}

export function isErrorInfo(error: unknown): error is InfoError {
  const err = error as InfoError;
  return (
    !!err &&
    typeof err === 'object' &&
    typeof err.reason === 'string' &&
    typeof err.description === 'string' &&
    typeof err.metadata === 'object'
  );
}

export function isNotFoundError(error: unknown): error is ResponseError {
  return isErrorInfo(error) && error.reason === 'NOT_FOUND';
}

export function isResponseError(error: unknown): error is ResponseError {
  const err = error as ResponseError;
  return (
    !!err &&
    typeof err === 'object' &&
    typeof err.code === 'number' &&
    typeof err.message === 'string'
  );
}
