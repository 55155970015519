export const RoleGroups = [
  {
    slug: 'common',
    roles: ['owner', 'editor', 'viewer'],
  },
  {
    slug: 'devices',
    roles: ['devices.editor', 'devices.viewer'],
  },
  {
    slug: 'dashboards',
    roles: ['dashboards.editor', 'dashboards.viewer'],
  },
  {
    slug: 'metrics',
    roles: ['metrics.editor', 'metrics.viewer'],
  },
  {
    slug: 'workflows',
    roles: ['workflows.editor', 'workflows.viewer'],
  },
  {
    slug: 'projects',
    roles: ['projects.editor'],
  },
] as const;

export const Roles = RoleGroups.flatMap(g => g.roles);

export type Role = (typeof Roles)[number];

export const AssignableRoleGroups = RoleGroups.map(g => ({
  slug: g.slug,
  roles: (g.roles as unknown as Role[]).filter(r => r !== 'owner') as Omit<
    Role,
    'owner'
  >[],
}));

export const AssignableRoles = Roles.filter(r => r !== 'owner') as Omit<
  Role,
  'owner'
>[];

export function roleFromSlug(slug: string): Role | undefined {
  return Roles.find(role => role === slug);
}
