import { RouteObject } from 'react-router';

export default {
  path: 'settings',
  lazy: () => import('./layout'),
  children: [
    {
      index: true,
      lazy: () => import('./page'),
    },
    {
      path: 'leave',
      lazy: () => import('./leave/page'),
    },
    {
      path: 'delete',
      lazy: () => import('./delete/page'),
    },
    {
      path: 'members',
      lazy: () => import('./members/page'),
      children: [
        {
          path: 'invite',
          lazy: () => import('./members/invite/page'),
        },
        {
          path: 'code/create',
          lazy: () => import('./members/code/create/page'),
        },
        {
          path: 'code/:codeId',
          lazy: () => import('./members/code/[codeId]/page'),
        },
      ],
    },
  ],
} satisfies RouteObject;
