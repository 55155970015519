export const AUTH_ERRORS = [
  'ERR_INVALID_SESSION_TOKEN',
  'INVALID_SESSION_TOKEN',
  'ERR_EXPIRED_SESSION',
  'EXPIRED_SESSION',
  'ERR_INVALID_TOKEN',
  'INVALID_TOKEN',
  'ERR_UNKNOWN_USER',
  'UNKNOWN_USER',
];
