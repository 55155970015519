import { API_URL, AUTH_APP_URL } from '@/config';
import { errorToast } from '@/lib/error-toast';
import { keepPreviousData, QueryClient } from '@tanstack/react-query';
import { isErrorInfo } from 'api/auth';
import { AUTH_ERRORS } from 'api/auth/errors';
import { Mutex } from 'async-mutex';
import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import qs from 'qs';

const returnTo = (url: string) => `${AUTH_APP_URL}/login?return_to=${url}`;
const ignoreError = () => window.location.pathname.includes('/shared');

export const apiClient = axios.create({
  adapter: 'fetch',
  baseURL: API_URL,
  timeout: 35000,
  withCredentials: true,
  paramsSerializer: params =>
    qs.stringify(params, {
      arrayFormat: 'repeat',
    }),
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      placeholderData: keepPreviousData,
      staleTime: 10000,
      refetchOnWindowFocus: true,
    },
    mutations: {
      onError: err => errorToast(err),
    },
  },
});

const mutex = new Mutex();

apiClient.interceptors.request.use(
  request => {
    setProjectHeaders(request.headers);
    return request;
  },
  err => Promise.reject(err),
);

apiClient.interceptors.response.use(
  res => res,
  async (err: AxiosError) => {
    const originalRequest = err.config;
    const res = err.response;
    if (!originalRequest || !res || res.status !== 401 || ignoreError()) {
      return Promise.reject(err);
    }

    const redirectURL = returnTo(encodeURIComponent(window.location.href));
    const errResponse = res.data;

    if (!isErrorInfo(errResponse) || AUTH_ERRORS.includes(errResponse.reason)) {
      window.location.replace(redirectURL);
      return Promise.reject(err);
    }

    if (
      errResponse.reason !== 'ERR_EXPIRED_TOKEN' &&
      errResponse.reason !== 'EXPIRED_TOKEN'
    ) {
      return Promise.reject(err);
    }

    if (mutex.isLocked()) {
      await mutex.waitForUnlock();
      return apiClient(originalRequest);
    }

    const release = await mutex.acquire();

    try {
      await apiClient.get('/auth/self-service/login?refresh=true');
      return apiClient(originalRequest);
    } catch {
      window.location.replace(redirectURL);
    } finally {
      release();
    }

    return Promise.reject(err);
  },
);

const sharedRegexp = new RegExp(/.*\/shared\/([^/]*).*/);

function getProjectId(): string | undefined {
  let projectId = localStorage.getItem('currentProjectId');
  if (projectId) {
    return projectId;
  }
  return sharedRegexp.exec(window.location.pathname)?.['1'];
}

export const setProjectHeaders = (headers: AxiosRequestHeaders) => {
  const projectId = getProjectId();
  if (projectId) {
    headers.set('x-project-id', projectId);
  }
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    headers.set('authorization', `Bearer ${accessToken}`);
  }
  return headers;
};
