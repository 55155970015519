import { IconComponent } from '../icons/type';
import { cn } from '../utils';

export const EmptyIcon: IconComponent = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn('h-[100px] w-[100px]', className)}
      viewBox="0 0 647.64 632.17"
      {...props}
    >
      <path
        d="M411.15 142.17H236.64a15.02 15.02 0 0 0-15 15v387.85l-2 .61-42.81 13.11a8 8 0 0 1-10-5.3L39.5 137.47a8 8 0 0 1 5.3-9.99l65.98-20.2 191.25-58.54L368 28.55a7.99 7.99 0 0 1 9.99 5.3l32.55 106.32Z"
        fill="#f2f2f2"
      />
      <path
        d="M449.23 140.17 410 12.03A17 17 0 0 0 388.77.75l-92.75 28.4-191.24 58.54-92.75 28.4A17.02 17.02 0 0 0 .75 137.32l134.08 437.93a17.03 17.03 0 0 0 16.26 12.03 16.79 16.79 0 0 0 4.97-.75l63.58-19.46 2-.62v-2.09l-2 .61-64.17 19.65a15.01 15.01 0 0 1-18.73-9.95L2.67 136.73A14.98 14.98 0 0 1 12.62 118l92.75-28.4L296.6 31.06l92.75-28.4a15.16 15.16 0 0 1 4.4-.66 15.01 15.01 0 0 1 14.33 10.61l39.05 127.56.62 2h2.08Z"
        fill="#3f3d56"
      />
      <path
        d="M122.68 127.82a9.02 9.02 0 0 1-8.61-6.37l-12.88-42.07a9 9 0 0 1 5.97-11.24L283.1 14.28a9 9 0 0 1 11.24 5.97l12.88 42.07a9.01 9.01 0 0 1-5.97 11.24l-175.94 53.87a8.98 8.98 0 0 1-2.63.4Z"
        fill="#97d16b"
      />
      <circle cx="190.15" cy="24.95" r="20" fill="#97d16b" />
      <circle cx="190.15" cy="24.95" r="12.66" fill="#fff" />
      <path
        d="M602.64 582.17h-338a8.51 8.51 0 0 1-8.5-8.5v-405a8.51 8.51 0 0 1 8.5-8.5h338a8.51 8.51 0 0 1 8.5 8.5v405a8.51 8.51 0 0 1-8.5 8.5Z"
        fill="#e6e6e6"
      />
      <path
        d="M447.14 140.17h-210.5a17.02 17.02 0 0 0-17 17v407.8l2-.6v-407.2a15.02 15.02 0 0 1 15-15h211.12Zm183.5 0h-394a17.02 17.02 0 0 0-17 17v458a17.02 17.02 0 0 0 17 17h394a17.02 17.02 0 0 0 17-17v-458a17.02 17.02 0 0 0-17-17Zm15 475a15.02 15.02 0 0 1-15 15h-394a15.02 15.02 0 0 1-15-15v-458a15.02 15.02 0 0 1 15-15h394a15.02 15.02 0 0 1 15 15Z"
        fill="#3f3d56"
      />
      <path
        d="M525.64 184.17h-184a9.01 9.01 0 0 1-9-9v-44a9.01 9.01 0 0 1 9-9h184a9.01 9.01 0 0 1 9 9v44a9.01 9.01 0 0 1-9 9Z"
        fill="#97d16b"
      />
      <circle cx="433.64" cy="105.17" r="20" fill="#97d16b" />
      <circle cx="433.64" cy="105.17" r="12.18" fill="#fff" />
    </svg>
  );
};
