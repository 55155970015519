import { ListResult, PaginationArgs } from '@/api/common';
import { PaginationResult } from 'api/types/common';
import { z } from 'zod';
import { apiClient } from '../client';
import {
  CodeInvitation,
  CodeInvitationSchema,
  CreateProjectRequest,
  CreateProjectRequestSchema,
  Invitation,
  LeaveProjectRequest,
  ListProjectPermissionsResponse,
  Project,
  ProjectMember,
  ProjectMemberSchema,
  ProjectWithRoles,
  UpdateProjectRequest,
  UpdateProjectRequestSchema,
} from './types';
import { createQuery } from '../query/query';
import { setLocalStorageValue } from 'ui/hooks/use-local-storage';

export function getCurrentProjectId() {
  return localStorage.getItem('currentProjectId');
}

export function setCurrentProjectId(projectId: string) {
  return setLocalStorageValue('currentProjectId', projectId);
}

export function unsetCurrentProjectId() {
  return localStorage.removeItem('currentProjectId');
}

export function createProject(req: CreateProjectRequest) {
  const body = CreateProjectRequestSchema.parse(req);
  return apiClient.post<Project>('/projects', body).then(res => res.data);
}

export function updateProject(req: UpdateProjectRequest) {
  const { projectId, ...body } = UpdateProjectRequestSchema.parse(req);
  return apiClient
    .put<Project>(`/projects/${projectId}`, body)
    .then(res => res.data);
}

export function deleteProject(projectId: string) {
  return apiClient.delete<void>(`/projects/${projectId}`).then(res => res.data);
}

export function listProjects(req?: PaginationArgs, signal?: AbortSignal) {
  const { page = 1, pageSize = 100 } = req ?? {};
  return apiClient
    .get<ListResult<'projects', ProjectWithRoles>>('/projects', {
      params: { page, pageSize },
      signal,
    })
    .then(res => res.data);
}
export const listProjectsQuery = createQuery((req?: PaginationArgs) => ({
  queryKey: ['projects', 'list', req],
  queryFn: ({ signal }) => listProjects(req, signal),
}));

export function getProject(projectId: string | null, signal?: AbortSignal) {
  if (!projectId) {
    return Promise.resolve(null);
  }
  return apiClient
    .get<Project>(`/projects/${projectId}`, { signal })
    .then(res => res.data);
}
export const getProjectQuery = createQuery((projectId: string | null) => ({
  queryKey: ['projects', 'get', projectId],
  queryFn: ({ signal }) => getProject(projectId, signal),
}));

export function listProjectMembers(projectId: string, signal?: AbortSignal) {
  return apiClient
    .get<PaginationResult<'members', ProjectMember>>(
      `/projects/${projectId}/members`,
      {
        signal,
      },
    )
    .then(res => res.data);
}

export const InviteUserSchema = ProjectMemberSchema.pick({
  projectId: true,
  roles: true,
}).extend({ userEmail: z.array(z.string().email()) });
export type InviteUserRequest = z.infer<typeof InviteUserSchema>;

export type InviteUserResponse = {
  invitationUrl: string;
  invitation: Invitation;
};

export function inviteUser({ projectId, ...body }: InviteUserRequest) {
  return apiClient
    .post<InviteUserResponse>(`/projects/${projectId}/invitations`, body)
    .then(res => res.data);
}

export const UpdateMemberSchema = ProjectMemberSchema.pick({
  projectId: true,
  userId: true,
  roles: true,
});
export type UpdateMemberRequest = z.infer<typeof UpdateMemberSchema>;

export function updateMember({
  projectId,
  userId,
  roles,
}: UpdateMemberRequest) {
  return apiClient
    .put<ProjectMember>(`/projects/${projectId}/members/${userId}`, { roles })
    .then(res => res.data);
}

export function removeMember(projectId: string, userId: string) {
  return apiClient
    .delete<void>(`/projects/${projectId}/members/${userId}`)
    .then(res => res.data);
}

export function transferOwnership(projectId: string, userId: string) {
  return apiClient
    .put<void>(`/projects/${projectId}/ownership`, {
      receivingUserId: userId,
    })
    .then(res => res.data);
}

export function listInvitations(projectId: string, signal?: AbortSignal) {
  return apiClient
    .get<{ invitations: Invitation[] }>(`/projects/${projectId}/invitations`, {
      signal,
    })
    .then(res => res.data.invitations);
}

export function deleteInvitation(projectId: string, userEmail: string) {
  return apiClient
    .delete<void>(`/projects/${projectId}/invitations/${userEmail}`)
    .then(res => res.data);
}

export const CreateCodeInvitationSchema = CodeInvitationSchema.pick({
  projectId: true,
  roles: true,
  expiresAt: true,
});
export type CreateCodeInvitationRequest = z.infer<
  typeof CreateCodeInvitationSchema
>;
export function createCodeInvitation(
  req: CreateCodeInvitationRequest,
  signal?: AbortSignal,
) {
  const { projectId, ...body } = CreateCodeInvitationSchema.parse(req);

  return apiClient
    .post<CodeInvitation>(`/projects/${projectId}/code-invitations`, body, {
      signal,
    })
    .then(res => res.data);
}

export const UpdateCodeInvitationSchema = CodeInvitationSchema.pick({
  id: true,
  projectId: true,
  roles: true,
  expiresAt: true,
});
export type UpdateCodeInvitationRequest = z.infer<
  typeof UpdateCodeInvitationSchema
>;

export function updateCodeInvitation({
  projectId,
  id,
  ...body
}: UpdateCodeInvitationRequest) {
  return apiClient
    .put<CodeInvitation>(`/projects/${projectId}/code-invitations/${id}`, body)
    .then(res => res.data);
}

export function regenerateCodeInvitation(projectId: string, id: string) {
  return apiClient
    .post<CodeInvitation>(
      `/projects/${projectId}/code-invitations/${id}/regenerate`,
    )
    .then(res => res.data);
}

export function deleteCodeInvitation(projectId: string, id: string) {
  return apiClient
    .delete<CodeInvitation>(`/projects/${projectId}/code-invitations/${id}`)
    .then(res => res.data);
}

export interface ListCodeCollectionsRequest extends PaginationArgs {
  projectId: string;
}
export function listCodeInvitations(
  projectId: string,
  params?: PaginationArgs,
  signal?: AbortSignal,
) {
  return apiClient
    .get<ListResult<'invitations', CodeInvitation>>(
      `/projects/${projectId}/code-invitations`,
      {
        params,
        signal,
      },
    )
    .then(res => res.data);
}

export function getCodeInvitation(
  projectId: string,
  codeId: string,
  signal?: AbortSignal,
) {
  return apiClient
    .get<CodeInvitation>(`/projects/${projectId}/code-invitations/${codeId}`, {
      signal,
    })
    .then(res => res.data);
}

type AcceptInvitationRequest = {
  projectId: string;
  userEmail?: string;
  code?: string;
};

export function acceptInivitation(req: AcceptInvitationRequest) {
  return apiClient.get<void>(`/projects/${req.projectId}/invitation`, {
    params: {
      code: req.code,
    },
  });
}

export function joinProject(code: string) {
  return apiClient.post<Project>(
    `/projects/join`,
    {},
    {
      params: {
        code,
      },
    },
  );
}

export function listProjectPermissions(
  projectId: string,
  signal?: AbortSignal,
) {
  return apiClient
    .get<ListProjectPermissionsResponse>(`/projects/${projectId}/permissions`, {
      signal,
    })
    .then(res => res.data.permissions);
}
export const listProjectPermissionsQuery = createQuery((projectId: string) => ({
  queryKey: ['project', projectId, 'permissions'],
  queryFn: ({ signal }) => listProjectPermissions(projectId, signal),
}));

export function leaveProject({ projectId, userId }: LeaveProjectRequest) {
  return apiClient
    .delete<void>(`/projects/${projectId}/members/${userId}`)
    .then(res => res.data);
}
