import { Navigate, type RouteObject } from 'react-router';
import DevicePageError from './[deviceId]/error';
import VariableErrorPage from './[deviceId]/variables/[variableId]/error';
import CommandErrorPage from './[deviceId]/commands/error';

export default [
  {
    path: 'devices',
    lazy: () => import('./page'),
    children: [
      {
        path: 'create',
        lazy: () => import('./create/page'),
      },
    ],
  },
  {
    path: 'devices/:deviceId',
    lazy: () => import('./[deviceId]/layout'),
    errorElement: <DevicePageError />,
    children: [
      {
        index: true,
        element: <Navigate to="variables" replace />,
      },
      {
        path: 'variables',
        lazy: () => import('./[deviceId]/variables/page'),
      },
      {
        path: 'commands',
        lazy: () => import('./[deviceId]/commands/page'),
      },
    ],
  },
  {
    path: 'devices/:deviceId/variables',
    errorElement: <VariableErrorPage />,
    children: [
      {
        path: 'create',
        lazy: () => import('./[deviceId]/variables/create/page'),
      },
      {
        path: ':variableId',
        lazy: () => import('./[deviceId]/variables/[variableId]/page'),
      },
      {
        path: ':variableId/edit',
        lazy: () => import('./[deviceId]/variables/[variableId]/edit/page'),
      },
    ],
  },
  {
    path: 'devices/:deviceId/commands',
    errorElement: <CommandErrorPage />,
    children: [
      {
        path: 'create',
        lazy: () => import('./[deviceId]/commands/create/page'),
      },
      {
        path: ':commandId',
        lazy: () => import('./[deviceId]/commands/[commandId]/page'),
      },
    ],
  },
] satisfies RouteObject[];
