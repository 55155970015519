import { isNotFoundError } from '@/lib/error';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'ui/components/button';
import { Result, ResultContent, ResultTitle } from 'ui/components/result';

export default function DashboardErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError();

  if (isNotFoundError(error)) {
    return (
      <Result className="grow" type="error">
        <ResultTitle>{t('dashboards.error.notFound.title')}</ResultTitle>
        <ResultContent>
          <Button asChild>
            <Link to="/dashboards">
              {t('dashboards.error.notFound.button')}
            </Link>
          </Button>
        </ResultContent>
      </Result>
    );
  }

  throw error;
}
