import { acceptInivitation } from '@/api/projects';
import { selectProject } from '@/hooks/useProjectSelect';
import type { LoaderFunctionArgs } from 'react-router-dom';

export async function loader({ request, params }: LoaderFunctionArgs) {
  const { projectId } = params;

  const url = new URL(request.url);
  const userEmail = url.searchParams.get('email') ?? undefined;
  const code = url.searchParams.get('code') ?? undefined;

  if (!projectId) {
    return;
  }

  await acceptInivitation({
    projectId,
    userEmail,
    code,
  });
  selectProject(projectId);
}
