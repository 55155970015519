import { createBrowserRouter, Navigate } from 'react-router-dom';

import { APP_BASENAME } from '@/config';

import { ErrorBoundary } from '@/containers/ErrorBoundary';
import adminRoutes from './admin/routes';
import dashboardsRoutes from './dashboards/routes';
import devicesRoutes from './devices/routes';
import inviteRoutes from './invite/routes';
import projectRoutes from './settings/routes';
import sharedRoutes from './shared/routes';
import workflowsRoutes from './workflows/routes';

export const router = createBrowserRouter(
  [
    {
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: '/select-project',
          lazy: () => import('./select-project/page'),
        },
        {
          path: '/start',
          lazy: () => import('./start/page'),
        },
        sharedRoutes,
        adminRoutes,
        inviteRoutes,
        {
          path: '/',
          lazy: () => import('./layout'),
          children: [
            { index: true, element: <Navigate to="devices" /> },
            ...dashboardsRoutes,
            ...devicesRoutes,
            workflowsRoutes,
            projectRoutes,
            { path: '*', element: <Navigate to="devices" /> },
          ],
        },
      ],
    },
  ],
  { basename: APP_BASENAME },
);
