import ruTranslation from './translation.ru';
import ruZod from './zod.ru';
import ruForm from './form.ru';
import ruError from './error.ru';
import enTranslation from './translation.en';
import enZod from './zod.en';
import enForm from './form.en';
import enError from './error.en';
import { translationDiff } from './missing-translations';
// import { translationDiff } from './missing-translations';

export const translations = {
  ru: {
    translation: ruTranslation,
    zod: ruZod,
    form: ruForm,
    error: ruError,
  },
  en: {
    translation: enTranslation,
    zod: enZod,
    form: enForm,
    error: enError,
  },
} as const;

console.log(
  'translation diff',
  translationDiff(translations.ru, translations.en),
);
