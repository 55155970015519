export default {
  common: {
    create: 'Создать',
    save: 'Сохранить',
    cancel: 'Отменить',
    add: 'Добавить',
    connect: 'Подключение',
    connection: 'Подключение',
    general: 'Общие',
    loading: 'Загрузка...',
    noData: 'Нет данных',
    on: 'Вкл',
    off: 'Выкл',
    edit: 'Редактировать',
    delete: 'Удалить',
    actions: 'Действия',
    search: 'Поиск',
    value: 'Значение',
    select: 'Выбрать...',
    schedule: 'Расписание',
    start: 'Начало',
    end: 'Конец',
    from: 'Начало',
    to: 'Конец',
    time: 'Время',
    and: 'И',
    or: 'Или',
    weekdaysShort: {
      mo: 'Пн',
      tu: 'Вт',
      we: 'Ср',
      th: 'Чт',
      fr: 'Пт',
      sa: 'Сб',
      su: 'Вс',
    },
    preview: 'Предпросмотр',
    changesSaved: 'Изменения сохранены',
    join: 'Присоединиться',
    copy: 'Скопировать',
  },
  glossary: {
    dashboards: 'Инфопанели',
    device: 'Устройство',
    devices: 'Устройства',
    variable: 'Переменная',
    variables: 'Переменные',
    command: 'Команда',
    commands: 'Команды',
    workflows: 'Правила',
    settings: 'Настройки',
    adminPanel: 'Панель администратора',
    project: 'Проект',
    projects: 'Проекты',
    member: 'Участник',
    members: 'Участники',
    triggers: 'Триггеры',
    interval: 'Интервал',
    expression: 'Выражение',
    number: 'Число',
    user: 'Пользователь',
    users: 'Пользователи',
    license: 'Лицензия',
    licenses: 'Лицензии',
    documentation: 'Документация',
  },
  notifications: {
    changesSaved: 'Изменения сохранены',
    serverError: 'Ошибка сервера',
    badRequest: 'Отправлено неправильное значение',
    otherError: 'Что-то пошло не так...',
    valueSent: 'Значение успешно отправлено',
  },
  navigation: {
    profile: 'Профиль',
    about: 'О приложении',
    logout: 'Выйти',
    admin: {
      system: 'Информация о системе',
    },
  },
  fields: {
    id: 'Идентификатор',
    name: 'Название',
    label: 'Название',
    description: 'Описание',
    email: 'Email',
  },
  deleteDevice: 'Удалить устройство',
  dashboards: {
    panelsCount_one: '{{count}} панель',
    panelsCount_few: '{{count}} панелей',
    panelsCount_many: '{{count}} панелей',
    create: {
      title: 'Создание инфопанели',
    },
    empty: {
      title: 'В этом проекте нет инфопанелей',
      description: 'Вы можете создать новую инфопанель',
    },
    share: {
      triggerText: 'Поделиться',
      submitText: 'Поделиться',
      titleShared: 'Ссылка на инфопанель',
      titleToShare: 'Поделиться инфопанелью?',
    },
    delete: {
      triggerText: 'Удалить инфопанель',
    },
    fields: {
      name: 'Название инфопанели',
    },
    panels: {
      create: {
        triggerText: 'Добавить панель',
        title: 'Создание панели',
      },
      edit: {
        title: 'Информация о панели',
      },
      delete: {
        title: 'Удалить панель?',
      },
      messages: {
        selectVariable: 'Выберите переменную',
        noVariableFound: 'Переменная не найдена.',
        selectPanel: 'Выберите панель...',
        searchPanel: 'Поиск панели...',
        noPanelFound: 'Панель не найдена.',
        selectCommand: 'Выберите команду...',
        selectDashboard: 'Выберите инфопанель...',
      },
      fields: {
        title: 'Заголовок',
        format: 'Форматирование',
        view: 'Отображение',
        data: 'Данные',
        units: 'Единицы измерения',
        unitsPlaceholder: 'Получить из переменной',
        min: 'Минимальное значение',
        max: 'Максимальное значение',
        label: 'Ярлык',
        aggregationMethod: 'Метод агрегации',
        precision: 'Точность',
      },
      types: {
        command: {
          title: 'Команда',
        },
        dashboards: {
          title: 'Список инфопанелей',
        },
        gauge: {
          title: 'Числовое значение',
        },
        timeseries: {
          title: 'График временного ряда',
          fields: {
            drawStyle: 'Стиль начертания',
            fillOpacity: 'Прозрачность заливки',
            lineWidth: 'Толщина линии',
            showLegend: 'Показывать легенду',
            showPoints: 'Показывать точки',
            pointSize: 'Размер точки',
          },
          drawStyles: {
            lines: 'Линии',
            histogram: 'Гистограмма',
            points: 'Точки',
          },
        },
        slider: {
          title: 'Слайдер',
          fields: {
            step: 'Шаг',
            marks: 'Засечки',
            orientation: 'Ориентация',
            horizontal: 'Горизонтально',
            vertical: 'Вертикально',
          },
        },
        switch: {
          title: 'Переключатель',
        },
        table: {
          title: 'Таблица',
        },
        logs: {
          title: 'Логи',
          fields: {
            showLabel: 'Ярлык',
            showTime: 'Время',
            wrapLines: 'Перенос строк',
          },
        },
      },
    },
    error: {
      notFound: {
        title: 'Инфопанель не найдена',
        button: 'К инфопанелям',
      },
    },
  },
  devices: {
    create: {
      title: 'Создание устройства',
      nameIdHelperText:
        'В дальнейшем идентификатор устройтва нельзя будет изменить',
    },
    empty: {
      title: 'В этом проекте нет устройств',
      description: 'Вы можете создать новое устройство',
    },
    params: {
      title: 'Параметры устройства',
      showHide: 'Показать/Скрыть параметры',
    },
    connect: {
      description: 'Выберите способ подключения к устройству',
    },
    fields: {
      name: 'Идентификатор',
      sendInterval: 'Частота отправки сообщения',
      tokens: 'Токены',
      tokensTooltip: 'Минимальная длина токена 14 символов',
      generateToken: 'Сгенерировать токен',
    },
    error: {
      notFound: {
        title: 'В этом проекте нет такого устройства',
        button: 'Вернуться к устройствам',
      },
    },
  },
  variables: {
    create: {
      title: 'Создание переменной',
    },
    empty: {
      title: 'В этом устройстве нет переменных',
      description: 'Вы можете создать новую переменную',
    },
    delete: {
      title: 'Удалить переменную?',
    },
    params: 'Параметры переменной',
    type: {
      binary: 'Бинарное',
      number: 'Число',
      string: 'Строка',
      location: 'Координаты',
    },
    fields: {
      name: 'Идентификатор',
      canControl: 'Контролируемое',
      canControlTooltip:
        'Возможность отправки значений на переменную в правилах или инфопанелях',
      type: 'Тип значения',
      valueField: 'Поле JSON',
      unit: 'Единицы измерения',
      valueRangeMin: 'Мин. значение',
      valueRangeMax: 'Макс. значение',
      colorScheme: 'Цвет',
      icon: 'Иконка',
      states: 'Состояния',
    },
    validation: {
      NOT_UNIQUE:
        'Переменная с таким идентификатором уже есть в этом устройстве',
    },
    aggregation: {
      count: 'Количество',
      sum: 'Сумма',
      avg: 'Среднее',
      min: 'Мин',
      max: 'Макс',
      first: 'Первое',
      last: 'Последнее',
      selectFunction: 'Выберите функцию...',
      functionNotFound: 'Функция не найдена',
    },
    error: {
      notFound: {
        title: 'В этом устройстве нет такой переменной',
        button: 'Вернуться к устройству',
      },
    },
  },
  commands: {
    sendCommandButton: 'Запустить',
    sendSubmitToast: 'Команда отправлена на устройство',
    commandPreviewLabel: 'Название команды',
    create: {
      title: 'Создание команды',
    },
    delete: { title: 'Удалить команду?' },
    params: {
      optionsPlaceholder: 'Выберите значение',
      stringPlaceholder: 'Введите текст',
    },
    validation: {
      paramsOrTemplate:
        'У команды должен быть задан хотя бы один параметр или шаблон',
      required: 'Введите значение параметра',
    },
    paramTypes: {
      bool: 'Boolean',
      number: 'Число',
      string: 'Строка',
    },
    fields: {
      name: 'Идентификатор',
      showTemplate: 'Использовать шаблон сообщения',
      params: 'Параметры',
      addParam: 'Добавить параметр',
      paramItemLabel: 'Параметр {{index}}',
      paramType: 'Тип',
      paramMin: 'Минимальное значение',
      paramMax: 'Максимальное значение',
      paramOptions: 'Опции',
    },
    error: {
      notFound: {
        title: 'В этом устройстве нет такой команды',
        button: 'Вернуться к устройству',
      },
    },
  },
  workflows: {
    actionsCount_one: '{{count}} действие',
    actionsCount_few: '{{count}} действия',
    actionsCount_many: '{{count}} действий',
    create: {
      title: 'Создание правила',
    },
    empty: {
      title: 'В этом проекте нет правил',
      description: 'Вы можете создать новое правило',
    },
    fields: {
      enabled: {
        on: 'Правило включено',
        off: 'Правило выключено',
      },
      executeOnMessage: 'Пришло новое значение',
      onTime: 'Определенное время',
    },
    validation: {
      errorInBlock: 'Ошибка в блоке',
    },
    actions: {
      add: 'Добавить действие',
      delete: 'Удалить действие',
      steps: 'Шаги',
      if: {
        title: 'Если',
        endOfCondition: 'Конец условия',
        else: 'Иначе',
      },
      set: {
        title: 'Вычислить',
        variablePlaceholder: 'Название переменной',
      },
      sendCommand: {
        title: 'Команда устройства',
      },
      setVariable: {
        title: 'Отправить на устройство',
        menuTitle: 'Отправить значение',
        deleteVariable: 'Удалить переменную',
      },
      operator: {
        placeholder: 'оператор',
        number: {
          eq: 'равно',
          neq: 'не равно',
          gt: 'больше, чем',
          gte: 'больше или равно',
          lt: 'меньше, чем',
          lte: 'меньше или равно',
          between: 'между',
        },
      },
      param: {
        input: 'Входные данные',
        expression: 'Выражение',
        reference: 'Ссылка',
        noValue: 'Нет значения',
        devicesAndVariables: 'Устройства и переменные',
        localVariables: 'Локальные переменные',
        selectValueType: 'Выберите тип значения',
        valueType: 'Тип значения',
      },
      condition: {
        delete: 'Удалить условие',
      },
    },
    error: {
      notFound: {
        title: 'Правило не найдено',
        button: 'К правилам',
      },
    },
  },

  units: {
    misc: 'Разное',
    temperature: 'Температура',
    number: 'Число',
    '%': 'Проценты (%)',
    '%H': 'Влажность (%H)',
    dB: 'Децибел (dB)',
    '°C': 'Цельсия (°С)',
    '°F': 'Фаренгейт (°F)',
    K: 'Кельвин (K)',
  },

  projects: {
    invite: 'Пригласить',
    settingsTitle: 'Настройки проекта',
    create: {
      title: 'Создание проекта',
      triggerButton: 'Создать проект',
    },
    validation: {
      NOT_UNIQUE: 'Идентификатор проекта не уникален',
    },
    join: {
      triggerText: 'Присоединиться',
      title: 'Присоединиться к проекту',
      fields: {
        code: 'Код',
      },
    },
    leave: {
      triggerText: 'Покинуть проект',
      title: 'Покинуть проект',
      description:
        'При выходе из проекты вы теряете доступ ко всем ресурсам внутри проекта.',
      submitText: 'Покинуть',
      errors: {
        LAST_OWNER:
          'Требуется передать права владельца другому участнику проекта.',
      },
    },
    delete: {
      triggerText: 'Удалить проект',
      title: 'Удалить проект',
      description:
        'При удалении проекта удаляются и все ресурсы внутри проекта.',
      submitText: 'Удалить',
    },
  },
  members: {
    invite: {
      successTitle: 'Приглашение успешно отправлено',
      link: 'Ссылка',
      title: 'Пригласить в проект',
      submitText: 'Пригласить',
    },
    editRoles: {
      title: 'Редактирование ролей пользователя',
      triggerText: 'Изменить роли',
    },
    transferOwnershipDialog: {
      triggerText: 'Передать права владения',
      title: 'Передать права владением проекта?',
      description: `Вы точно уверены? Вы не сможете отменить это действие!
      После передачи владения, вы получите роль "Редактор". Новый владелец
      может исключить вас из этого проекта.
      `,
      submitText: 'Передать права',
    },
    expelDialog: {
      triggerText: 'Исключить',
      title: 'Исключить пользователя из проекта?',
      description: 'Вы точно уверены? Вы не сможете отменить это действие!',
      submitText: 'Исключить',
    },
    fields: {
      fullName: 'Имя',
      roles: 'Роли',
      roleSelectPlaceholder: 'Выберите роль...',
    },
    roleGroups: {
      common: 'Общие',
      devices: 'Устройства',
      dashboards: 'Инфопанели',
      metrics: 'Данные переменных',
      workflows: 'Правила',
      projects: 'Проект',
    },
    roles: {
      owner: {
        title: 'Владелец',
        description: 'Полный доступ ко всем ресурсам внутри проекта',
      },
      editor: {
        title: 'Редактор',
        description:
          'Просмотр, создание, изменение и удаление всех ресурсов внутри проекта.',
      },
      viewer: {
        title: 'Читатель',
        description: 'Возможность просматривать все ресурсы внутри проекта.',
      },
      devices: {
        editor: {
          title: 'Редактор устройств',
          description:
            'Просмотр, создание, изменение и удаление всех устройств внутри проекта.',
        },
        viewer: {
          title: 'Просмотр устройств',
          description:
            'Возможность просматривать все устройства внутри проекта.',
        },
      },
      dashboards: {
        editor: {
          title: 'Редактор инфопанелей',
          description:
            'Просмотр, создание, изменение и удаление всех инфопанелей внутри проекта.',
        },
        viewer: {
          title: 'Просмотр инфопанелей',
          description:
            'Возможность просматривать все инфопанели внутри проекта.',
        },
      },
      metrics: {
        editor: {
          title: 'Редактор данных переменной',
          description:
            'Просмотр, изменение и удаление данных всех переменных внутри проекта.',
        },
        viewer: {
          title: 'Просмотр данных переменной',
          description:
            'Возможность просматривать данных всех переменных внутри проекта.',
        },
      },
      workflows: {
        editor: {
          title: 'Редактор правил',
          description:
            'Просмотр, изменение и удаление всех правил внутри проекта.',
        },
        viewer: {
          title: 'Просмотр правил',
          description: 'Возможность просматривать всех правил внутри проекта.',
        },
      },
      projects: {
        editor: {
          title: 'Редактор проекта',
          description:
            'Возможность вносить изменения в проект или приглашать участников.',
        },
      },
    },
    invitationSend: 'Приглашение отправлено',
    codeInvitation: {
      create: {
        title: 'Создать код приглашения',
      },
      update: {
        title: 'Изменить приглашение',
      },
      item: {
        title: 'Приглашение по коду',
        actions: {
          copyLink: 'Скопировать ссылку',
          copyCode: 'Копировать код',
          resetCode: 'Сбросить код',
          changeRoles: 'Изменить роли',
        },
      },
      messages: {
        codeUpdated: 'Код приглашения обновлен',
        linkCopied: 'Ссылка скопирована',
        codeCopied: 'Код приглашения скопирован',
      },
    },
  },
  user: {
    edit: {
      title: 'Настройки пользователя',
      generalSectionTitle: 'Общие',
      passwordSectionTitle: 'Пароль',
      editName: 'Изменить имя',
      changePassword: 'Изменить пароль',
    },
    fields: {
      email: 'Email',
      name: 'Имя',
      fullNamePlaceholder: 'Не указано',
      firstName: 'Имя',
      lastName: 'Фамилия',
      oldPassword: 'Старый пароль',
      newPassword: 'Новый пароль',
      confirmPassword: 'Повторите пароль',
    },
    validation: {
      confirmPasswordRequired: 'Пожалуйста подтвердите новый пароль',
      passwordsNotEqual: 'Пароли не совпадают!',
    },
  },
  appInfo: {
    title: 'Информация о приложении',
    fields: {
      language: 'Язык',
      appVersion: 'Версия приложения',
      releaseSHA: 'Хэш релиза',
      releaseDate: 'Дата релиза',
    },
  },
  admin: {
    system: {
      title: 'Информация о системе',
      cpu: 'CPU',
      cores: 'Ядер',
      memory: 'ОЗУ',
      disk: 'Хранилище',
      connectionErrorText: 'Не удалось подключиться к устройству',
    },
    projects: {
      columns: {
        name: 'Название',
        membersCount: 'Пользователей',
        devicesCount: 'Устройств',
        variablesCount: 'Переменных',
        workflowsCount: 'Правил',
        dashboardsCount: 'Инфопанелей',
        createdAt: 'Создан',
      },
      fields: {
        searchInputPlaceholder: 'Поиск по названию или идентификатору проекта',
      },
    },
    users: {
      actions: {
        label: 'Открыть меню',
        resetPassword: 'Сбросить пароль',
      },
      columns: {
        name: 'Имя',
        projectsCount: 'Проектов',
        createdAt: 'Создан',
        lastActiveAt: 'Последняя сессия',
      },
      fields: {
        searchInputPlaceholder: 'Поиск по имени или email...',
        newPassword: 'Новый пароль',
        confirmPassword: 'Повторите пароль',
      },
      validation: {
        confirmPasswordRequired: 'Подтвердите новый пароль',
        passwordsNotEqual: 'Пароли не совпадают',
      },
      resetPasswordDialog: {
        title: 'Сбросить пароль',
      },
      info: {
        userInfoTitle: 'Общая информация',
        projectsMemberTitle: 'Состоит в проектах',
      },
    },
    licenses: {
      activateDialog: {
        triggerText: 'Активировать',
        submitText: 'Активировать',
        title: 'Активация лицензии',
      },
      fields: {
        expiresAt: 'Дата истечения',
        licenseKey: 'Ключ лицензии',
        offlineActivation: 'Оффлайн активация',
        getActivationLinkButtonText: 'Получить QR код с ссылкой на сертификат',
        getActivationLinkHelperText:
          'Для получения QR кода, требуется заполнить лицензионный ключ',
        certificate: 'Сертификат',
      },
      validation: {
        attachCertificate: 'Загрузите сертификат',
      },
      messages: {
        noActiveLicense: {
          title: 'Нет активных лицензий',
          description: 'Срок активных лицензий истек',
        },
      },
    },
  },
  timeControl: {
    range: {
      from: 'Начало',
      to: 'Конец',
      lastPeriod: 'Предыдущий период времени',
      nextPeriod: 'Следующий период времени',
      zoomOut: 'Увеличить период времени',
    },
    execution: {
      interval: 'Интервал',
      selectInterval: 'Выбрать интервал',
    },
    rangeOptions: {
      last5m: 'Последние 5 минут',
      last10m: 'Последние 10 минут',
      last30m: 'Последние 30 минут',
      last1h: 'Последние 1 час',
      last3h: 'Последние 3 часа',
      last6h: 'Последние 6 часов',
      last12h: 'Последние 12 часов',
      last24h: 'Последние 24 часа',
      last2d: 'Последние 2 дня',
      last7d: 'Последние 7 дней',
      last30d: 'Последние 30 дней',
      last90d: 'Последние 90 дней',
      last180d: 'Последние 180 дней',
      last1y: 'Последний год',
      yesterday: 'Вчера',
      today: 'Сегодня',
    },
    refreshOptions: {
      off: 'Выкл',
      '1s': '1 сек',
      '2s': '2 сек',
      '5s': '5 сек',
      '10s': '10 сек',
      '30s': '30 сек',
      '1m': '1 мин',
      '5m': '5 мин',
      '15m': '15 мин',
      '30m': '30 мин',
    },
  },
  screens: {
    start: {
      title: 'Добро пожаловать',
      description:
        'Мы рады видеть тебя на нашей платформе. Создание нового проекта – это первый шаг к своей цели. <br /> Давай начнем!',
    },
    error: {
      title: 'Что то пошло не так...',
      errorId: 'ID ошибки',
      errorCode: 'Код ошибки',
      reloadPage: 'Перезагрузить страницу',
      toMain: 'На главную',
      dialog: {
        title: 'Кажется, что-то пошло не так...',
        subtitle: 'Уведомление уже отправлено нашей команде.',
        subtitle2:
          'Если вы хотите помочь, расскажите нам о том, что произошло ниже.',
        labelName: 'Имя',
        labelComments: 'Что произошло?',
        labelClose: 'Закрыть',
        labelSubmit: 'Отправить',
      },
    },
    pickProject: {
      title: 'Выберите проект',
    },
  },
} as const;
