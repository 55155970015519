import type { RouteObject } from 'react-router';
import DashboardErrorPage from './[dashboardSlug]/error';

export default [
  {
    path: 'dashboards',
    lazy: () => import('./layout'),
    children: [
      {
        index: true,
        lazy: () => import('./page'),
      },
      {
        path: 'create',
        lazy: () => import('./create/page'),
      },
    ],
  },
  {
    path: 'dashboards/:dashboardSlug',
    errorElement: <DashboardErrorPage />,
    children: [
      {
        index: true,
        lazy: () => import('./[dashboardSlug]/page'),
      },
      {
        path: 'panels/create',
        lazy: () => import('./[dashboardSlug]/panels/create/page'),
      },
      {
        path: 'panels/:panelId',
        lazy: () => import('./[dashboardSlug]/panels/[panelId]/page'),
      },
    ],
  },
] satisfies RouteObject[];
