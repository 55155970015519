import { ErrorScreen } from '@/components/ErrorScreen';

export function ErrorBoundary() {
  return <ErrorScreen />;
  // return <div>Error occured</div>;
  // return (
  //   <SentryErrorBoundary
  //     fallback={props => <ErrorScreen {...props} />}
  //     showDialog
  //     dialogOptions={{
  //       title: 'Кажется, что-то пошло не так...',
  //       subtitle: 'Уведомление уже отправлено нашей команде.',
  //       subtitle2:
  //         'Если вы хотите помочь, расскажите нам о том, что произошло ниже.',
  //       labelName: 'Имя',
  //       labelComments: 'Что произошло?',
  //       labelClose: 'Закрыть',
  //       labelSubmit: 'Отправить',
  //     }}
  //   >
  //     {children}
  //   </SentryErrorBoundary>
  // );
}
