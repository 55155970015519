// noinspection JSNonASCIINames

export default {
  common: {
    create: 'Create',
    save: 'Save',
    cancel: 'Cancel',
    add: 'Add',
    connect: 'Connect',
    connection: 'Connection',
    general: 'General',
    loading: 'Загрузка...',
    noData: 'No data',
    on: 'On',
    off: 'Off',
    edit: 'Edit',
    delete: 'Delete',
    actions: 'Actions',
    search: 'Search',
    value: 'Value',
    select: 'Select...',
    schedule: 'Schedule',
    start: 'Start',
    end: 'End',
    from: 'From',
    to: 'To',
    time: 'Time',
    and: 'And',
    or: 'Or',
    weekdaysShort: {
      mo: 'Mo',
      tu: 'Tu',
      we: 'We',
      th: 'Th',
      fr: 'Fr',
      sa: 'Sa',
      su: 'Su',
    },
    preview: 'Preview',
    changesSaved: 'Changes saved',
    join: 'Join',
    copy: 'Copy',
  },
  glossary: {
    dashboards: 'Dashboards',
    device: 'Device',
    devices: 'Devices',
    variable: 'Variable',
    variables: 'Variables',
    command: 'Command',
    commands: 'Commands',
    workflows: 'Workflows',
    settings: 'Settings',
    adminPanel: 'Admin Area',
    project: 'Project',
    projects: 'Projects',
    member: 'Member',
    members: 'Members',
    triggers: 'Triggers',
    interval: 'Interval',
    expression: 'Expression',
    number: 'Number',
    user: 'User',
    users: 'Users',
    license: 'License',
    licenses: 'Licenses',
    documentation: 'Documentation',
  },
  notifications: {
    changesSaved: 'Changes saved',
    serverError: 'Server Error',
    badRequest: 'Wrong value',
    otherError: 'Something went wrong...',
    valueSent: 'Value sent successfully',
  },
  navigation: {
    profile: 'Profile',
    about: 'About app',
    logout: 'Logout',
    admin: {
      system: 'System',
    },
  },
  fields: {
    id: 'ID',
    name: 'Name',
    label: 'Label',
    description: 'Description',
    email: 'Email',
  },

  deleteDevice: 'Delete Device',
  dashboards: {
    panelsCount_one: '{{count}} panel',
    panelsCount_other: '{{count}} panels',
    create: {
      title: 'Create dashboard',
    },
    empty: {
      title: 'There are no dashboards in this project',
      description: 'You can create a new dashboard',
    },
    share: {
      triggerText: 'Share',
      submitText: 'Share',
      titleShared: 'Link to dashboard',
      titleToShare: 'Share dashboard?',
    },
    delete: {
      triggerText: 'Delete dashboard',
    },
    fields: {
      name: 'Dashboard name',
    },
    panels: {
      create: {
        triggerText: 'Add panel',
        title: 'Create panel',
      },
      edit: {
        title: 'Panel info',
      },
      delete: {
        title: 'Delete panel?',
      },
      messages: {
        selectVariable: 'Select variable',
        noVariableFound: 'No variable found.',
        selectPanel: 'Select panel...',
        searchPanel: 'Search panel...',
        noPanelFound: 'No panel found.',
        selectCommand: 'Select command...',
        selectDashboard: 'Select dashboard...',
      },
      fields: {
        title: 'Title',
        format: 'Format',
        view: 'View',
        data: 'Data',
        units: 'Units',
        unitsPlaceholder: 'Get from variable',
        min: 'Min',
        max: 'Max',
        label: 'Label',
        aggregationMethod: 'Aggregation method',
        precision: 'Precision',
      },
      types: {
        command: {
          title: 'Command',
        },
        dashboards: {
          title: 'Dashboards list',
        },
        gauge: {
          title: 'Gauge',
        },
        timeseries: {
          title: 'Timeseries graph',
          fields: {
            drawStyle: 'Draw style',
            fillOpacity: 'Fill opacity',
            lineWidth: 'Line width',
            showLegend: 'Show legend',
            showPoints: 'Show points',
            pointSize: 'Poin size',
          },
          drawStyles: {
            lines: 'Lines',
            histogram: 'Histogram',
            points: 'Points',
          },
        },
        slider: {
          title: 'Slider',
          fields: {
            step: 'Step',
            marks: 'Marks',
            orientation: 'Orientation',
            horizontal: 'Horizontal',
            vertical: 'Vertical',
          },
        },
        switch: {
          title: 'Switch',
        },
        table: {
          title: 'Table',
        },
        logs: {
          title: 'Logs',
          fields: {
            showLabel: 'Label',
            showTime: 'Time',
            wrapLines: 'Wrap lines',
          },
        },
      },
    },
    error: {
      notFound: {
        title: 'Dashboard not found',
        button: 'To dashboards',
      },
    },
  },
  devices: {
    create: {
      title: 'Create Device',
      nameIdHelperText: 'Device name cannot be changed later',
    },
    empty: {
      title: 'There are no devices in this project',
      description: 'You can create a new device',
    },
    params: {
      title: 'Device params',
      showHide: 'Show/Hide params',
    },
    connect: {
      description: 'Choose device connection method',
    },
    fields: {
      name: 'Name',
      sendInterval: 'Send Interval',
      tokens: 'Tokens',
      tokensTooltip: 'Minimum token length 14 characters',
      generateToken: 'Generate Token',
    },
    error: {
      notFound: {
        title: 'Device not found',
        button: 'To devices',
      },
    },
  },
  variables: {
    create: {
      title: 'Create Variable',
    },
    empty: {
      title: 'There are no variables in this device',
      description: 'You can create a new variable',
    },
    delete: {
      title: 'Удалить переменную?',
    },
    params: 'Variable Parameters',
    type: {
      binary: 'Binary',
      number: 'Number',
      string: 'String',
      location: 'Location',
    },
    fields: {
      name: 'Name',
      canControl: 'Controlable',
      canControlTooltip:
        'Ability to send values to a variable in workflows or dashboards',
      type: 'Value Type',
      valueField: 'JSON Field',
      unit: 'Unit',
      valueRangeMin: 'Min',
      valueRangeMax: 'Max',
      colorScheme: 'Color',
      icon: 'Icon',
      states: 'States',
    },
    validation: {
      NOT_UNIQUE: 'Variable with the same name already exists in this device',
    },
    aggregation: {
      count: 'Count',
      sum: 'Sum',
      avg: 'Average',
      min: 'Min',
      max: 'Max',
      first: 'First',
      last: 'Last',
      selectFunction: 'Select function...',
      functionNotFound: 'Function not found',
    },
    error: {
      notFound: {
        title: 'Variable not found',
        button: 'To device',
      },
    },
  },
  commands: {
    sendCommandButton: 'Send',
    sendSubmitToast: 'The command has been sent to the device',
    commandPreviewLabel: 'Command name',
    create: {
      title: 'Create command',
    },
    delete: { title: 'Delete command?' },
    params: {
      optionsPlaceholder: 'Select value',
      stringPlaceholder: 'Enter text',
    },
    validation: {
      paramsOrTemplate:
        'The command must have at least one parameter or template',
      required: 'Enter parameter value',
    },
    paramTypes: {
      bool: 'Boolean',
      number: 'Number',
      string: 'String',
    },
    fields: {
      name: 'Name',
      showTemplate: 'Use message template',
      params: 'Parameters',
      addParam: 'Add parameter',
      paramItemLabel: 'Parameter {{index}}',
      paramType: 'Type',
      paramMin: 'Min',
      paramMax: 'Max',
      paramOptions: 'Options',
    },
    error: {
      notFound: {
        title: 'Command not found',
        button: 'Return to device',
      },
    },
  },
  workflows: {
    actionsCount_one: '{{count}} action',
    actionsCount_other: '{{count}} actions',
    create: {
      title: 'Create Workflow',
    },
    empty: {
      title: 'There are no workflows in this project',
      description: 'You can create a new workflow',
    },
    fields: {
      enabled: { on: 'Enabled', off: 'Disabled' },
      executeOnMessage: 'Execute on message',
      onTime: 'On time',
    },
    validation: {
      errorInBlock: 'Error in block',
    },
    actions: {
      add: 'Add action',
      delete: 'Remove action',
      steps: 'Steps',
      if: {
        title: 'If',
        endOfCondition: 'End of condition',
        else: 'Else',
      },
      set: {
        title: 'Compute',
        variablePlaceholder: 'Variable name',
      },
      sendCommand: {
        title: 'Device Command',
      },
      setVariable: {
        title: 'Send to Device',
        menuTitle: 'Send Value',
        deleteVariable: 'Remove Variable',
      },
      operator: {
        placeholder: 'operator',
        number: {
          eq: 'equals',
          neq: 'not equals',
          gt: 'greater than',
          gte: 'greater or equal to',
          lt: 'less than',
          lte: 'less or equal to',
          between: 'between',
        },
      },
      param: {
        input: 'Input',
        expression: 'Expression',
        reference: 'Reference',
        noValue: 'No value',
        devicesAndVariables: 'Devices and Variables',
        localVariables: 'Local Variables',
        selectValueType: 'Select Value type',
        valueType: 'Value type',
      },
      condition: {
        delete: 'Delete condition',
      },
    },
    error: {
      notFound: {
        title: 'Workflow not found',
        button: 'To workflows',
      },
    },
  },

  units: {
    misc: 'Misc',
    temperature: 'Temperature',
    number: 'Number',
    '%': 'Percent (%)',
    '%H': 'Humidity (%H)',
    dB: 'Decibel (dB)',
    '°С': 'Celsius (°С)',
    '°F': 'Fahrenheit (°F)',
    K: 'Kelvin (K)',
  },

  projects: {
    invite: 'Invite',
    settingsTitle: 'Project Settings',
    create: {
      title: 'Create Project',
      triggerButton: 'Create Project',
    },
    validation: {
      NOT_UNIQUE: 'Project with the same name already exists in this device',
    },
    join: {
      triggerText: 'Join',
      title: 'Join project',
      fields: {
        code: 'Code',
      },
    },
    leave: {
      triggerText: 'Leave project',
      title: 'Leave project',
      description:
        'When you leave this project, you lose access to all resources within the project.',
      submitText: 'Leave',
      errors: {
        LAST_OWNER:
          'You need to transfer owner rights to another project member.',
      },
    },
    delete: {
      triggerText: 'Delete project',
      title: 'Delete project',
      description:
        'When you delete this project, all resources within the project are also deleted.',
      submitText: 'Delete',
    },
  },
  members: {
    invite: {
      successTitle: 'Invitation successfully sent',
      link: 'Link',
      title: 'Invite to project',
      submitText: 'Invite',
    },
    editRoles: {
      title: 'Edit Member Roles',
      triggerText: 'Edit Roles',
    },
    transferOwnershipDialog: {
      triggerText: 'Transfer ownership',
      title: 'Transfer project ownership?',
      description: `Are you sure? You will not be able to undo this action! After the transfer of ownership, you will receive the role of "Editor". The new owner may exclude you from this project.
      `,
      submitText: 'Transfer ownership',
    },
    expelDialog: {
      triggerText: 'Exclude',
      title: 'Exclude member from project?',
      description: 'Are you sure? You will not be able to undo this action!',
      submitText: 'Exclude',
    },
    fields: {
      fullName: 'Name',
      roles: 'Roles',
      roleSelectPlaceholder: 'Select a role...',
    },
    roleGroups: {
      common: 'Common',
      devices: 'Devices',
      dashboards: 'Dashboards',
      metrics: 'Variables Data',
      workflows: 'Workflows',
      projects: 'Project',
    },
    roles: {
      owner: {
        title: 'Owner',
        description: 'Full access to all resources within this project.',
      },
      editor: {
        title: 'Editor',
        description:
          'View, create, modify and delete all resources within this project.',
      },
      viewer: {
        title: 'Viewer',
        description: 'View all resources within this project.',
      },
      devices: {
        editor: {
          title: 'Devices Editor',
          description:
            'View, create, modify and delete all devices within this project.',
        },
        viewer: {
          title: 'Devices Viewer',
          description: 'View all devices within this project.',
        },
      },
      dashboards: {
        editor: {
          title: 'Dashboards Editor',
          description:
            'View, create, modify and delete all dashboards within this project.',
        },
        viewer: {
          title: 'Dashboards Viewer',
          description: 'View all dashboards within this project.',
        },
      },
      metrics: {
        editor: {
          title: 'Metrics Editor',
          description:
            'View, modify and delete all metrics within this project.',
        },
        viewer: {
          title: 'Metrics Viewer',
          description: 'View all metrics within this project.',
        },
      },
      workflows: {
        editor: {
          title: 'Workflows Editor',
          description:
            'View, create, modify and delete all workflows within this project.',
        },
        viewer: {
          title: 'Workflows Viewer',
          description: 'View all workflows within this project.',
        },
      },
      projects: {
        editor: {
          title: 'Project Editor',
          description: 'Can make changes to the project and invite members.',
        },
      },
    },
    invitationSend: 'Invitation send',
    codeInvitation: {
      create: {
        title: 'Create invitation code',
      },
      update: {
        title: 'Change invitation',
      },
      item: {
        title: 'Invitation code',
        actions: {
          copyLink: 'Copy link',
          copyCode: 'Copy code',
          resetCode: 'Reset code',
          changeRoles: 'Change roles',
        },
      },
      messages: {
        codeUpdated: 'Code updated',
        linkCopied: 'Link copied',
        codeCopied: 'Code copied',
      },
    },
  },
  user: {
    edit: {
      title: 'User Settings',
      generalSectionTitle: 'General',
      passwordSectionTitle: 'Password',
      editName: 'Edit Name',
      changePassword: 'Change Password',
    },
    fields: {
      email: 'Email',
      name: 'Name',
      fullNamePlaceholder: 'Not Defined',
      firstName: 'First Name',
      lastName: 'Last Name',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
    },
    validation: {
      confirmPasswordRequired: 'Please confirm new password',
      passwordsNotEqual: 'Passwords do not match!',
    },
  },
  appInfo: {
    title: 'App Info',
    fields: {
      language: 'Language',
      appVersion: 'App version',
      releaseSHA: 'Release hash',
      releaseDate: 'Release date',
    },
  },
  admin: {
    system: {
      title: 'System Info',
      cpu: 'CPU',
      cores: 'Cores',
      memory: 'RAM',
      disk: 'Disk',
      connectionErrorText: 'Failed to connect to device',
    },
    projects: {
      columns: {
        name: 'Name',
        membersCount: 'Members',
        devicesCount: 'Devices',
        variablesCount: 'Variables',
        workflowsCount: 'Workflows',
        dashboardsCount: 'Dashboards',
        createdAt: 'Created',
      },
      fields: {
        searchInputPlaceholder: 'Search by project name or id',
      },
    },
    users: {
      actions: {
        label: 'Open menu',
        resetPassword: 'Reset password',
      },
      columns: {
        name: 'Name',
        projectsCount: 'Projects',
        createdAt: 'Registered',
        lastActiveAt: 'Last active',
      },
      fields: {
        searchInputPlaceholder: 'Search by name or email...',
        newPassword: 'New password',
        confirmPassword: 'Confirm Password',
      },
      validation: {
        confirmPasswordRequired: 'Confirm new password',
        passwordsNotEqual: 'Passwords not equal',
      },
      resetPasswordDialog: {
        title: 'Reset password',
      },
      info: {
        userInfoTitle: 'User Info',
        projectsMemberTitle: 'Member in',
      },
    },
    licenses: {
      activateDialog: {
        triggerText: 'Activate license',
        submitText: 'Activate',
        title: 'Activate license',
      },
      fields: {
        expiresAt: 'Expires at',
        licenseKey: 'License key',
        offlineActivation: 'Offline activation',
        getActivationLinkButtonText: 'Get a QR code with link to certificate',
        getActivationLinkHelperText:
          'To get a QR code, you need to fill in a license key',
        certificate: 'Certificate',
      },
      validation: {
        attachCertificate: 'Attach certificate',
      },
      messages: {
        noActiveLicense: {
          title: 'No active licenses',
          description: 'Active licenses expired',
        },
      },
    },
  },
  timeControl: {
    range: {
      from: 'From',
      to: 'To',
      lastPeriod: 'Last time period',
      nextPeriod: 'Next time period',
      zoomOut: 'Zoom out',
    },
    execution: {
      interval: 'Interval',
      selectInterval: 'Select interval',
    },
    rangeOptions: {
      last5m: 'Last 5 minutes',
      last10m: 'Last 10 minutes',
      last30m: 'Last 30 minutes',
      last1h: 'Last 1 hour',
      last3h: 'Last 3 hours',
      last6h: 'Last 6 hours',
      last12h: 'Last 12 hours',
      last24h: 'Last 24 hours',
      last2d: 'Last 2 days',
      last7d: 'Last 7 days',
      last30d: 'Last 30 days',
      last90d: 'Last 90 days',
      last180d: 'Last 180 days',
      last1y: 'Last year',
      yesterday: 'Yesterday',
      today: 'Today',
    },
    refreshOptions: {
      off: 'Off',
      '1s': '1s',
      '2s': '2s',
      '5s': '5s',
      '10s': '10s',
      '30s': '30s',
      '1m': '1m',
      '5m': '5m',
      '15m': '15m',
      '30m': '30m',
    },
  },

  screens: {
    start: {
      title: 'Welcome!',
      description:
        "We are happy to have you on our platform. Creating a new project is the first step to your goal. <br />Let's get started!",
    },
    error: {
      title: 'Something went wrong...',
      errorId: 'Error ID',
      errorCode: 'Error code',
      reloadPage: 'Reload page',
      toMain: 'To main page',
      dialog: {
        title: 'Something went wrong...',
        subtitle: 'The notification has already been sent to our team.',
        subtitle2:
          'If you would like to help, please tell us what happened below.',
        labelName: 'Name',
        labelComments: 'What happened?',
        labelClose: 'Close',
        labelSubmit: 'Send',
      },
    },
    pickProject: {
      title: 'Select project',
    },
  },
} as const;
