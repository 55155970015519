import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { makeZodI18nMap } from 'zod-i18n-map';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

import { translations } from '@/locales';
import { APP_DEFAULT_LANGUAGE, APP_LANGUAGES } from '@/config';
import { z } from 'zod';

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNs: 'translation';
    resources: (typeof translations)['ru'];
  }
}

i18n.on('languageChanged', lng => {
  dayjs.locale(lng);
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'en',
    fallbackLng: APP_DEFAULT_LANGUAGE,
    defaultNS: 'translation',

    resources:
      APP_LANGUAGES.length > 0
        ? Object.fromEntries(
            Object.entries(translations).filter(([key]) =>
              APP_LANGUAGES.includes(key),
            ),
          )
        : translations,

    interpolation: {
      escapeValue: false,
    },
  });

z.setErrorMap(
  makeZodI18nMap({
    ns: ['zod', 'form', 'translation'],
    handlePath: {
      keyPrefix: 'paths',
    },
  }),
);

export default i18n;
export const t = i18n.t;
