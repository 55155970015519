import { Navigate, RouteObject } from 'react-router';

export default {
  path: '/admin',
  lazy: () => import('./layout'),
  children: [
    { index: true, element: <Navigate to="/admin/system" /> },
    {
      path: 'system',
      lazy: () => import('./system/page'),
    },
    {
      path: 'projects',
      lazy: () => import('./projects/page'),
    },
    {
      path: 'users',
      children: [
        { index: true, lazy: () => import('./users/page') },
        { path: ':userId', lazy: () => import('./users/[userId]/page') },
      ],
    },
    {
      path: 'licenses',
      lazy: () => import('./licenses/page'),
    },
    {
      path: 'backups',
      lazy: () => import('./backups/page'),
    },
  ],
} satisfies RouteObject;
